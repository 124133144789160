import React from 'react';
import { graphql } from 'gatsby';
import * as Ink from '@carta/ink';
import Layout from '../components/Layout';

import Header from '../components/Header/Header';
import PreviewCard from '../components/Common/PreviewCard';

const Contributing = ({
  data: {
    allContributing: { nodes },
  },
}) => {
  const sortedContributing = nodes.sort((a, b) => a.frontmatter.title.localeCompare(b.frontmatter.title));
  return (
    <Layout pageTitle="How to contribute to ink">
      <Header
        title="How to contribute to ink"
        subtitle="Ink is Carta's design system. It is built specifically to fit Carta's needs, and is under consistent
          development as those needs grow."
      />
      <Ink.Box top="20" bottom="20px">
        <Ink.Text>
          Any developers that wish to contribute new ideas, iterations for a component, bug fixes or anything else
          should first go to our{' '}
          <Ink.Anchor preset="new-tab" href="https://eshares.slack.com/messages/ink">
            slack channel
          </Ink.Anchor>{' '}
          to check if the desired feature or component is already being developed or on the roadmap.
        </Ink.Text>
        <Ink.Text>
          To understand better how to ask in the channel for your specific case, you can read{' '}
          <Ink.Anchor href="/contributing/issues-requests">Issues and requests</Ink.Anchor>. If the ink team gives you a
          thumbs up for the development of your idea, you can read the following articles for important information on
          how to better contribute to ink:
        </Ink.Text>
      </Ink.Box>
      <Ink.Tiles columns={[1, 2, 2, 3, 4]}>
        {sortedContributing.map(node => (
          <PreviewCard
            key={node.frontmatter.title}
            title={node.frontmatter.title}
            text={node.frontmatter.subtitle}
            actionLabel="View guide"
            actionHref={`/contributing/${node.slug}`}
            previewWithCheckerboard
          />
        ))}
      </Ink.Tiles>
    </Layout>
  );
};

export default Contributing;

export const query = graphql`
  query ContributingQuery {
    allContributing: allMdx(filter: { fileAbsolutePath: { regex: "/content/contributing/" } }) {
      nodes {
        frontmatter {
          title
          subtitle
        }
        slug
      }
    }
  }
`;
